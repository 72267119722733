<template>
    <div class="restpassword">
        <div class="merchatlogin-wrap">
            <div class="merchatlogin-wrap-top">
                <img src="@/assets/sj_bj.png" alt="">
            </div>
            <div class="merchatlogin-wrap-login">
                <div class="tips">商家微信授权后可使用微信登录~</div>
                <img src="@/assets/images/wx.png" alt="">
                <div class="submit" @click="toLogin">登录</div>
            </div>
            <!-- 登录方式 -->
            <login-way :loginFrom="0"></login-way>
        </div>
    </div>
</template>

<script>
    import {
        authLogin
    } from '@/http/api.js'
    import LoginWay from './LoginChildren/LoginWay';
    export default {
        components: {
            LoginWay
        },
        data() {
            return {
                zappid: 'wx2072f5b290784490'
            }
        },
        mounted() {
            let code = this.$route.query.code;
            if (code) {
                this.loading = true;
                this.code = code;
                this.authLogin();
            }
        },
        methods: {
            toLogin() {
                let redirectUri = document.URL;
                window.location.href =
                    'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.zappid + '&redirect_uri=' +
                    encodeURI(redirectUri) + '&response_type=code&scope=snsapi_base&state=login#wechat_redirect';
            },
            // 执行授权接口
            async authLogin() {
                const res = await authLogin({
                    data: {
                        appid: this.zappid,
                        code: this.code,
                    }
                })
                this.loading = false;
                if (res.code == 200) {
                    this.$LStorage.setItem("shopUserInfo", res.data);
                    let that = this;
                    this.$toast.success({message:'登录成功', onClose:function(){
                        that.$router.replace({
                            path: "/merchantwork"
                        });
                    }}); 
                } else {
                    this.$dialog.alert({
                        title: '提示',
                        message: res.msgs
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .restpassword {
        width: 100%;
        min-height: 100vh;
        background: #f6f6f6;

        .merchatlogin-wrap {
            .merchatlogin-wrap-top {
                img {
                    width: 100%;
                    height: 4.54rem;
                }
            }

            .merchatlogin-wrap-login {
                width: 5.6rem;
                margin: 0 auto;
                background: #fff;
                border-radius: 0.07rem;
                padding: 0.39rem 0.45rem 0.45rem 0.52rem;
                box-sizing: border-box;
                margin-top: 0.45rem;

                .tips {
                    font-size: 0.28rem;
                    text-align: center;
                    color: #939393;
                }

                img {
                    display: block;
                    width: 1.12rem;
                    margin: 0.5rem auto 0;
                }

                .submit {
                    width: 4.55rem;
                    height: 0.73rem;
                    border-radius: 0.04rem;
                    font-size: 0.26rem;
                    color: #fff;
                    background: #e14e2a;
                    margin-top: 0.5rem;
                    text-align: center;
                    line-height: 0.73rem;
                }

            }
        }
    }
</style>
