<template>
    <div class="way-box">
        <van-divider :style="{ color: '#A4A4A4', borderColor: '#E8E8E8' }">其他登录方式</van-divider>
        <div class="way-list flex">
            <router-link tag="div" to="/wechatlogin" class="item" v-if="loginFrom != 0">
                <img src="@/assets/images/dl01.png" alt="">
                <p>微信登录</p>
            </router-link>
            <router-link tag="div" to="/merchatlogin" class="item" v-if="loginFrom != 1">
                <img src="@/assets/images/dl02.png" alt="">
                <p>账号登录</p>
            </router-link>
            <router-link tag="div" to="/phonelogin" class="item" v-if="loginFrom != 2">
                <img src="@/assets/images/dl03.png" alt="">
                <p>短信登录</p>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        components: {},
        props: {
            loginFrom: {
                type: Number,
                default () {
                    return 1
                }
            }
        },
        data() {
            return {}
        },
    }
</script>

<style scoped lang="scss">
    .way-box {
        padding: 0.8rem 0.5rem;

        .way-list {
            justify-content: space-around;
            margin-top: 0.5rem;
            padding: 0 0.8rem;

            .item {
                font-size: 0.24rem;
                color: #A4A4A4;
                text-align: center;

                img {
                    width: 0.73rem;
                    height: 0.73rem;
                    margin-bottom: 0.2rem;
                }
            }
        }
    }
</style>
